"use client";

import { useLiveQuery } from "next-sanity/preview";
import { ReactNode } from "react";
import { SanityDataWithPreviewConfig } from "./getAppFolderPageProps";

type Props<T> = { sanityData: SanityDataWithPreviewConfig<T> } & { children: (data: T) => ReactNode };

export const SanityPreviewLiveQuery = <T,>(props: Props<T>) => {
  if (!props?.sanityData?._raw_data || !props?.sanityData?._previewConfig)
    throw new Error("Missing preview data and/or preview config. Did you forget to send it as props?");

  const [previewData] = useLiveQuery(
    props.sanityData._raw_data,
    props.sanityData._previewConfig.query,
    props.sanityData._previewConfig.queryParams
  );

  return props.children(previewData);
};

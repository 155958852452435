import { BoxProps, ResponsiveObject } from "@biblioteksentralen/react";
import { Modify } from "@biblioteksentralen/types";
import { ComponentProps } from "react";
import { Breakpoint, breakpoints, isBreakpoint } from "../../utils/hooks/useBreakpointValue";
import SanityImage from "./SanityImage";

const getNextBreakpoint = (allBreakpoints: string[], breakpoint: Breakpoint): Breakpoint | undefined => {
  const breakpointIndex = breakpoints.indexOf(breakpoint);
  return breakpoints.slice(breakpointIndex + 1).find((point) => allBreakpoints.includes(point));
};

type SanityImageWithResponsiveSizeProps = Modify<
  ComponentProps<typeof SanityImage>,
  { resolution?: ResponsiveObject<number> }
>;

/**
 * useBreakpointValue does not resolve on the server but only on the client, therefore we use HideWithCSS instead
 */
export const SanityImageWithResponsiveSize = ({ resolution, ...props }: SanityImageWithResponsiveSizeProps) => {
  if (typeof resolution === "number") {
    return <SanityImage resolution={resolution} {...props} />;
  }

  if (!resolution) return null;

  const allBreakpoints = Object.keys(resolution);

  return (
    <>
      {allBreakpoints.filter(isBreakpoint).map((breakpoint) => {
        const display: BoxProps["display"] = { base: "none" };
        display[breakpoint] = "block";
        const nextBreakpoint = getNextBreakpoint(allBreakpoints, breakpoint);
        if (nextBreakpoint) display[nextBreakpoint] = "none";

        return (
          <SanityImage {...props} key={breakpoint} resolution={resolution[breakpoint] ?? 0} chakraProps={{ display }} />
        );
      })}
    </>
  );
};
